import {AiOutlineInstagram, AiOutlineLinkedin} from 'react-icons/ai'
import { BsTwitterX } from "react-icons/bs";
import { SiPatreon } from "react-icons/si";
import { FaYoutube } from "react-icons/fa";

export const links = [
    {id: 1, link: '#', title: 'Home'},
    {id: 2, link: '#about', title: 'About'},
    {id: 3, link: '#services', title: 'Services'},
    /*{id: 4, link: '#portfolio', title: 'Portfolio'},*/
    {id: 5, link: '#contact', title: 'Contact'}
]


export const socials = [
    {id: 1, link: 'https://www.instagram.com/imori_studio', icon: <AiOutlineInstagram/>},
    {id: 2, link: 'https://twitter.com/ImoriStudio', icon: <BsTwitterX/>},
    {id: 3, link: 'https://www.patreon.com/Mamurius', icon: <SiPatreon/>},
    {id: 4, link: 'https://www.youtube.com/@IMORISTUDIO', icon: <FaYoutube />},
    {id: 5, link: 'https://www.linkedin.com/company/imori-studio', icon: <AiOutlineLinkedin/>}
]