import {AiOutlineInstagram, AiOutlineLinkedin} from 'react-icons/ai'
import { BsTwitterX } from "react-icons/bs";
import { SiPatreon } from "react-icons/si";
import { FaYoutube } from "react-icons/fa";


const data = [
    {id: 1, link: 'https://www.instagram.com/imori_studio', icon: <AiOutlineInstagram/>},
    {id: 2, link: 'https://twitter.com/ImoriStudio', icon: <BsTwitterX/>},
    {id: 3, link: 'https://www.patreon.com/Mamurius', icon: <SiPatreon/>},
    {id: 4, link: 'https://www.youtube.com/@IMORISTUDIO', icon: <FaYoutube />},
    {id: 5, link: 'https://www.linkedin.com/company/imori-studio', icon: <AiOutlineLinkedin/>}
]

export default data